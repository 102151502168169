.navLink:hover {
  color: var(--blue);
  display: block;
  content: '';
  border-bottom: solid 3px var(--blue);
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}

.dropdownMenu {
  display: none;
  position: absolute;
  right: -182px;
  background-color: #333333;
  min-width: 218px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 15px 0px 39px 30px;
  z-index: 1;
}

.dropdownMenu {
  color: #fff;
  /* padding: 8px 16px; */
  text-decoration: none;
  font-family: 'SansPro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  display: block;
}

.dropdownItem {
  margin-bottom: 8px;
}

.dropdownMenu a:hover {
  background-color: #333333;
  color: var(--blue);
}

.dropdownItem:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

@media all and (max-width: 768px) {
  .dropdownMenu {
    right: -182px;
  }
}