.certificateContainer {
    max-width: 1300px;
    margin: 0 auto;
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
}

.certificateTitle {
    width: 100%;
    margin: 0 auto;
    text-align: left;
}

.certificateTitleText {
    font-family: 'Bebas', 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    color: var(--white);
}

.certificateTitleDescription {
    padding: 30px 0 0 0;
    width: 746px;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 31px;
    color: var(--white);
}

.descriptionBlue {
    color: var(--blue);
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .certificateContainer {
      max-width: 1024px;
      padding-top: 50px;
    }
    .certificateTitle {
      /* max-width: 100%; */
      /* margin-left: 20px; */
      text-align: left;
      padding-bottom: 40px;
    }
    .certificateTitleText {
      font-size: 50px;
    }
    .certificateTitleDescription {
      font-size: 20px;
      width: 746px;
      line-height: 25.14px;
    }
  }
  
  @media all and (max-width: 768px) and (min-width: 480px) {
    .certificateContainer {
      max-width: 768px;
      padding-top: 40px;
    }
    .certificateTitle {
      /* max-width: 100%; */
      /* margin-left: 20px; */
      text-align: left;
      padding-bottom: 20px;
    }
    .certificateTitleText {
      font-size: 40px;
    }
    .certificateTitleDescription {
      font-size: 16px;
      width: 473px;
      line-height: 20.11px;
    }
  }
  
  @media all and (max-width: 480px) and (min-width: 360px) {
    .certificateContainer {
      max-width: 480px;
      padding-top: 20px;
    }
    .certificateTitle {
      /* max-width: 100%; */
      text-align: left;
      padding-bottom: 20px;
    }
    .certificateTitleText {
      font-size: 30px;
    }
    .certificateTitleDescription {
      font-size: 16px;
      width: 100%;
      padding-top: 20px;
      line-height: 20.11px;
      padding-bottom: 15px;
    }
  }
  
  @media all and (max-width: 360px) {
    .certificateContainer {
      max-width: 360px;
      padding-top: 20px;
    }
    .certificateTitleText {
      font-size: 30px;
    }
    .certificateTitle {
      /* max-width: 100%; */
      text-align: left;
      padding-bottom: 10px;
    }
    .certificateTitleDescription {
      font-size: 14px;
      width: 100%;
      padding-top: 10px;
      line-height: 17.6px;
      padding-bottom: 15px;
    }
  }