.header {
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
}
.mainDesctop {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6%;
}

.mainScreenLG {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  padding-bottom: 70px;
}

.phoneSms {
 display: inline-flex;
 gap: 25px;
}

@media all and (min-width: 1024px) {
  .header {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 67px 20px 100px 20px;
    justify-content: space-between;
  }
  .mainScreenLG {
    display: none;
  }
}
@media all and (max-width: 1024px) {
  .header {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 1023px) {
  .header {
    max-width: 1023px;
    /* padding: 20px; */
    padding: 20px 0 0 0;
    margin-bottom: 0;
    margin-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }
  .mainDesctop {
    display: none;
  }
}
@media all and (max-width: 480px) and (min-width: 360px){
  .header {
    max-width: 99%;
    padding: 20px;
    /* padding-left: 0; */
    /* margin-left: 20px; */
    margin-bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .mainDesctop {
    display: none;
  }
  .mainScreenLG {
    gap: 5px;
    padding: 0;
  }
  .phoneSms {
    gap: 15px;
   }
   .phone {
    width: 24px;
    height: 24px;
  }
  .sms {
    width: 34px;
    height: 24px;
  }
}
@media all and (max-width: 360px) {
  .header {
    max-width: 360px;
    padding: 20px;
    /* padding-left: 0;
    margin-left: 20px; */
    margin-bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .mainDesctop {
    display: none;
  }
  .mainScreenLG {
    gap: 5px;
    padding-bottom: 20px;
  }
  .phoneSms {
    gap: 10px;
  }
  .phone {
    width: 20px;
    height: 20px;
  }
  .sms {
    width: 30px;
    height: 20px;
  }
  
}
