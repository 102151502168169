.navbar {
  max-width: 500px;
  margin-right: 5px;
}

.navList {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  gap: 5%;
}

.navLink {
  display: inline-block;
  color: var(--white);
  font-family: 'SansPro', sans-serif;
  font-size: 22px;
  font-weight: 600;
  
}

.navLink:hover {
  color: var(--blue);
  display:block;
  content: '';
  border-bottom: solid 3px var(--blue);
}

@media all and (max-width: 1060px) and (min-width: 1024px) {
  .navList {
    margin-left: -20px;
  }
}

@media all and (max-width: 1024px) {
  .navbar {
    max-width: 422px;
  }
  .navList {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 422px;
  }
  .navLink {
    color: var(--white);
    font-size: 18px;
    font-weight: 600;
    font-family: 'SansPro', sans-serif;
  }
  .navLink:hover {
    color: var(--blue);
  }
  .navLink:hover + .underLine {
    display: block;
  }
  .underLine {
    display: none;
    width: 100%;
    border: 0.5px solid var(--blue);
  }
}


