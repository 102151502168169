.certificate {
  padding: 50px 0 70px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.certificateSlider {
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.certificateSliderPrevArrow {
  position: absolute;
  left: 0;
  top: 40%;
  background-color: gray;
  padding: 10px;
  opacity: 0.4;
  border-radius: 30%;
  z-index: 999;
  /* transform: translateX(-100%); */
}

.certificateSliderPrevArrow img {
  width: 30px;
}

.certificateSliderPrevArrow:hover {
  scale: 1.1;
  transition: 0.3s;
  cursor: pointer;
  opacity: 0.8;
}

.certificateSliderNextArrow {
  position: absolute;
  right: 10px;
  top: 40%;
  background-color: gray;
  padding: 10px;
  opacity: 0.4;
  border-radius: 30%;
  /* transform: translateX(100%); */
}

.certificateSliderNextArrow img {
  width: 30px;
}

.certificateSliderNextArrow:hover {
  scale: 1.1;
  transition: 0.3s;
  cursor: pointer;
  opacity: 0.8;
}

.certificateSliderContainer {
  display: flex;
}

.certificateSliderContainerClickLeft {
  width: 210%;
  display: flex;
  animation: left 10s infinite linear;
}

.certificateSliderContainerClickRight {
  width: 210%;
  display: flex;
  animation: right 10s infinite linear;
}

.certificateSliderElement {
  padding: 10px;
}

.certificateSliderElement img {
  width: 210px;
  height: 297px;
}

.tikerCertificate {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.tikerCertificateRight {
  transform: translate(30%, 0);
}

.tikerCertificateLeft {
  transform: translate(-30%, 0);
}

.tikerCertificate.active {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.tikerCertificate img {
  width: 100%;
  height: auto;
}

.slide {
  flex: 0 0 100%;
  transition: transform 0.5s;
  opacity: 0;
  visibility: hidden;
}

.slide.active {
  opacity: 1;
  visibility: visible;
}

.certificateButton {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  padding-top: 20px;
}

.certificateButtonLink {
  font-family: 'SansPro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: var(--white);
  padding-right: 6px;
}

.certificateButtonLink:hover {
  color: var(--blue);
  text-decoration: underline;
  transition: 0.2s;
  cursor: pointer;
}

.certificateButton img {
  width: 12px;
  height: 12px;
}

.certificateSliderElements {
  /* width: 200%; */
  display: flex;
  align-items: flex-end;
  /* animation: scroll 30s infinite linear; */
}

@media all and (max-width: 1024px) and (min-width: 768px) {
  .certificate {
    max-width: 1024px;
    padding: 20px;
  }
  .certificateTitle {
    padding: 20px;
  }
  
  .certificateTitle {
    max-width: 1024px;
    text-align: left;
    padding-bottom: 40px;
  }
  .certificateTitleDescription {
    font-size: 20px;
    width: 746px;
  }
}

@media all and (max-width: 768px) and (min-width: 480px) {
  .certificate {
    max-width: 768px;
    padding: 20px;
    padding: 20px;
    padding-bottom: 70px;
  }
  .certificateTitle {
    padding: 20px;
  }
  .tikerCertificate {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;
  }
  .certificateSliderElement {
    width: 30%;
    height: 30%;
    
  }
  .certificateTitle {
    max-width: 768px;
    text-align: left;
    padding-bottom: 20px;
  }
  .certificateTitleDescription {
    font-size: 16px;
    width: 473px;
  }
}

@media all and (max-width: 480px) and (min-width: 360px) {
  .certificate {
    max-width: 99%;
    padding: 20px;
    padding-bottom: 70px;
  }
  .certificateTitle {
    padding: 20px;
  }
  .tikerCertificate {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;
  }
  .certificateSliderElement {
    width: 30%;
    height: 30%;
  }
  .certificateTitle {
    max-width: 480px;
    text-align: left;
    padding-bottom: 20px;
  }
  .certificateTitleDescription {
    font-size: 16px;
    width: 100%;
  }
}

@media all and (max-width: 360px) {
  .certificate {
    max-width: 360px;
    padding: 20px;
    padding-bottom: 70px;
  }
  .certificateTitle {
    padding: 20px;
  }
  .tikerCertificate {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;
  }
  .certificateSliderElement {
    width: 30%;
    height: 30%;
    
  }
  .certificateTitle {
    max-width: 360px;
    text-align: left;
    padding-bottom: 10px;
  }
  .certificateTitleDescription {
    font-size: 14px;
    width: 100%;
  }
}

@keyframes scroll {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
