.container {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: space-between;
  padding: 50px 20px 0 20px;
  gap: 1%;
}

.titleDiv {
  max-width: 1190px;
}

.title {
  width: 100%;
  white-space: normal;
  color: var(--white);
  font-size: 80px;
  line-height: 85px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
  font-family: 'Bebas', 'SansPro', sans-serif;
  font-stretch: ultra-condensed;
}

.titleBlue {
  color: var(--blue);
}

.rightDescr {
  max-width: 360px;
  align-self: flex-end;
  color: var(--white);
  white-space: nowrap;
  font-family: 'SansPro', sans-serif;
  font-weight: 400;
  text-align: right;
  font-size: 25px;
  line-height: 40px;
  padding-bottom: 14px;
}

.br1 {
  display: none;
}
.br2 {
  display: none;
}
.br3 {
  display: block;
}
/* rightBlock  */
@media all and (max-width: 1105px) and (min-width: 1024px) {
  .rightDescr {
    white-space: pre-wrap;
  }
}
@media all and (max-width: 613px) and (min-width: 510px) {
  .rightDescr {
    white-space: pre-wrap;
  }
}
@media all and (max-width: 480px) and (min-width: 360px) {
  .rightDescr {
    white-space: pre-wrap;
  }
}

/* leftblock  */
/* @media all and (max-width: 1264px) and (min-width: 1025px) {
  .br1 {
    display: block;
  }
  .br2 {
    display: none;
  }
  .br3 {
    display: block;
  }
} */
/* @media all and (max-width: 1072px) and (min-width: 1025px) {
  .br1 {
    display: block;
  }
  .br2 {
    display: none;
  }
  .br3 {
    display: block;
  }
} */
@media all and (max-width: 848px) and (min-width: 768px) {
  .br1 {
    display: block;
  }
  .br2 {
    display: none;
  }
  .br3 {
    display: block;
  }
}
@media all and (max-width: 711px) and (min-width: 584px) {
  .br1 {
    display: block;
  }
  .br2 {
    display: none;
  }
  .br3 {
    display: block;
  }
  /* .br4 {
    display: block;
  }
  .br5 {
    display: block;
  } */
}
@media all and (max-width: 584px) and (min-width: 510px) {
  .br1 {
    display: block;
  }
  .br2 {
    display: none;
  }
  .br3 {
    display: block;
  }
}
@media all and (max-width: 480px) and (min-width: 361px) {
  .br1 {
    display: block;
  }
  .br2 {
    display: block;
  }
  .br3 {
    display: block;
  }
}
@media all and (max-width: 394px) and (min-width: 361px) {
  .br1 {
    display: block;
  }
  .br2 {
    display: block;
  }
  .br3 {
    display: block;
  }
}


@media all and (max-width: 1200px) and (min-width: 1024px) {
  .titleDiv {
    max-width: 1190px;
    min-width: 700px;
  }
}

@media all and (max-width: 1270px) and (min-width: 1024px) {
  .container {
    margin-bottom: 10px;
    padding-top: 20px;
    max-width: 99%;
  }
  .titleDiv {
    max-width: 761px;
    min-width: 440px;
  }
  .title {
    font-size: 65px;
    line-height: 65px;
  }

  .rightDescr {
    max-width: 360px;
    font-size: 18px;
    line-height: 24px;
  }
  .rightDescrP {
    font-size: 18px;
  }
}
@media all and (max-width: 1024px) and (min-width: 768px) {
  .container {
    margin-bottom: 10px;
    padding-top: 20px;
    max-width: 99%;
  }
  .titleDiv {
    max-width: 761px;
    min-width: 440px;
  }
  .title {
    font-size: 50px;
    line-height: 56px;
  }

  .rightDescr {
    max-width: 360px;
    font-size: 18px;
    line-height: 24px;
  }
  .rightDescrP {
    font-size: 18px;
  }
}

@media all and (max-width: 768px) and (min-width: 510px) {
  .container {
    margin-bottom: 10px;
    padding-top: 20px;
    max-width: 99%;
  }
  .titleDiv {
    max-width: 435px;
  }
  .title {
    font-size: 40px;
    line-height: 45px;
  }

  .titleBlue {
    font-size: 40px;
  }

  .rightDescr {
    max-width: 296px;
    font-size: 16px;
    line-height: 24px;
    /* white-space: pre-line; */
    padding-bottom: 8px;
  }
  .rightDescrP {
    font-size: 16px;
  }
}

@media all and (max-width: 510px) and (min-width: 360px) {
  .container {
    margin-bottom: 10px;
    padding-top: 20px;
    max-width: 99%;
  }

  .titleDiv {
    max-width: 435px;
    min-width: 250px;
  }

  .title {
    font-size: 25px;
    line-height: 28px;
  }

  .titleBlue {
    font-size: 25px;
  }

  .rightDescr {
    max-width: 296px;
    font-size: 12px;
    line-height: 24px;
    padding-bottom: 5px;
  }
  .rightDescrP {
    font-size: 12px;
  }

  .titleDivIphone .title {
    font-size: 17px;
    line-height: 28px;
    letter-spacing: -1px;
  }

  .titleDivIphone .titleBlue {
    font-size: 17px;
    letter-spacing: -1px;
  }
}

@media all and (max-width: 400px) and (min-width: 360px) {
  .rightDescr {
    padding-bottom: 4px;
  }
}

@media all and (max-width: 360px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-top: 20px;
    max-width: 99%;
  }

  .title {
    max-width: 100%;
    font-size: 25px;
    line-height: 28px;
    white-space: pre-wrap;
  }

  .titleBlue {
    font-size: 25px;
  }

  .rightDescr {
    max-width: 100%;
    font-size: 12px;
    line-height: 24px;
    white-space: pre-wrap;
  }
  .rightDescrP {
    font-size: 12px;
  }
}
