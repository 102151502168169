.logoLink {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* width: 12.938em;
  height: 4.75em; */
  width: 270px;
  /* height: 100px; */
  /* width: 18.938em; */
  height: 6.75em;
  
}
.logo {
  /* width: 175px;
  height: 70px; */
  /* width: 100%;
  height: 100%; */
  width: 270px;
  height: 100px;
  /* width: 250px;
  height: 85px; */
}

.logo:hover {
  cursor: pointer;
}

@media all and (max-width: 1024px) and (min-width:768px){
  .logoLink {
    margin-left: 5%;
  }
  .logo {
    width: 90%;
    height: 90%;
  }
}
@media all and (max-width: 768px) and (min-width: 480px){
  .logoLink {
    margin-left: 9%;
  }
  .logo {
    width: 90%;
    height: 90%;
  }
}
@media all and (max-width: 768px) {
  /* .logoLink {
    justify-content: center;
  } */
  .logo {
    width: 90%;
    height: 90%;
  }
}
@media all and (max-width: 480px) and (min-width: 360px){
  .logoLink {
    margin-left: 15%;
    justify-content: center;
  }
  .logo {
    /* width: 80%;
    height: 80%; */
    width: 165px;
    height: 60px;
  }
}
@media all and (max-width: 360px) {
  .logoLink {
    width: auto;
    margin-left: 0;
    justify-content: flex-end;
  }
  .logo {
    width: 80%;
    height: 55%;
    /* width: 135px;
    height: 50px; */
    margin-left: 30px;
  }
}