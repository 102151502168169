.infoBlock {
  width: 320px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 5%;
}

.links {
  color: var(--white);
  /* font-size: 18px; */
  font-size: 20px;
  font-weight: 600;
  font-family: 'SansPro', sans-serif;
  line-height: 30px;
  padding-left: 10px;
}

.links:hover {
  color: var(--blue);
}

@media all and (max-width: 1000px) {
  .infoBlock {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
  }
}
