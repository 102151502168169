@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: 300;
  src: local('BebasNeue-Light'),
    url('./fonts/BebasNeue-Light.woff2') format('woff2'),
    url('./fonts/BebasNeue-Light.woff') format('woff');
}

@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: 400;
  src: local('BebasNeue-Regular'),
    url('./fonts/BebasNeue-Regular.woff2') format('woff2'),
    url('./fonts/BebasNeue-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Bebas';
  font-style: normal;
  font-weight: 600;
  src: local('BebasNeue-Bold'),
    url('./fonts/BebasNeue-Bold.woff2') format('woff2'),
    url('./fonts/BebasNeue-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SansPro';
  font-style: normal;
  font-weight: 700;
  src: local('SourceSansPro-Bold'),
    url('./fonts/SourceSansPro-Bold.woff2') format('woff2'),
    url('./fonts/SourceSansPro-Bold.woff') format('woff');
}

@font-face {
  font-family: 'SansPro';
  font-style: normal;
  font-weight: 300;
  src: local('SourceSansPro-Light'),
    url('./fonts/SourceSansPro-Light.woff2') format('woff2'),
    url('./fonts/SourceSansPro-Light.woff') format('woff');
}

@font-face {
  font-family: 'SansPro';
  font-style: normal;
  font-weight: 400;
  src: local('SourceSansPro-Regular'),
    url('./fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('./fonts/SourceSansPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'SansPro';
  font-style: normal;
  font-weight: 600;
  src: local('SourceSansPro-SemiBold'),
    url('./fonts/SourceSansPro-SemiBold.woff2') format('woff2'),
    url('./fonts/SourceSansPro-SemiBold.woff') format('woff');
}

:root {
  --black: #333333;
  --blue: #008fff;
  --whiteLightness: 100%;
  --white: hsl(0, 0%, var(--whiteLightness));
  --grayF4: hsl(0, 0%, calc(var(--whiteLightness) - 4%));
  --greyF3: hsl(0, 0%, calc(var(--whiteLightness) - 5%));
  --greyEC: hsl(0, 0%, calc(var(--whiteLightness) - 7%));
  --greyD9: hsl(0, 0%, calc(var(--whiteLightness) - 15%));
  --greyC4: hsl(0, 0%, calc(var(--whiteLightness) - 23%));
  --grey99: hsl(0, 0%, calc(var(--whiteLightness) - 40%));
  --grey66: hsl(0, 0%, calc(var(--whiteLightness) - 60%));
  --maxWidth: 1920px;
}

body {
  padding: 0;
  margin: 0 auto;
  background-color: var(--black);
  font-size: 14px;
  line-height: 16px;
  font-family: 'Roboto', serif;
}

* {
  color: var(--white);
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.border_bottom {
  width: 100%;
  padding: 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #FFFFFF4D;
  margin-top: 20px;
}
@media screen and (max-width: 480px) {
  .border_bottom {
    width: 99%;
  }
}