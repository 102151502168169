.container {
    max-width: 1300px;
    margin-bottom: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 105px;
    background: url(../../../img/404.svg) no-repeat;
    background-size: contain;
    background-position: 50%;
}

.notFound_title {
    font-family: 'Bebas', 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 113.2%;
    color: #FFFFFF;
}

.notFound_subtitle {
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 113.2%;
    color: #FFFFFF;
}

.notFound_link{
    display: inline-flex;
    color: #008FFF;
    border-bottom: 1px solid #008FFF;
}

.notFound_link_span:hover {
    cursor: pointer;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
    .container {
        max-width: 1024px;
        padding: 20px;
        gap: 50px;
    }
    p {
        font-size: 25px;
    }
} 
@media screen and (max-width: 768px) and (min-width: 480px) {
    .container {
        max-width: 768px;
        padding: 20px;
        gap: 50px;
    }
    p {
        font-size: 18px;
    }
    .notFound_title {
        font-size: 40px;
    }
    .notFound_img  {
        display: flex;
        justify-content: center
    }
    .notFound_img img {
        width: 80%;
    }
} 
@media screen and (max-width: 480px) and (min-width: 360px) {
    .container {
        max-width: 480px;
        padding: 20px;
        gap: 50px;
        flex-direction: column;
    }
    p {
        font-size: 16px;
    }
    .notFound_title {
        font-size: 30px;
    }
    .notFound_img  {
        display: flex;
        justify-content: center
    }
    .notFound_img img {
        width: 80%;
    }
} 
@media screen and (max-width: 360px)  {
    .container {
        max-width: 480px;
        padding: 20px;
        gap: 50px;
        flex-direction: column;
    }
    p {
        font-size: 14px;
    }
    .notFound_title {
        font-size: 20px;
    }
    .notFound_img  {
        display: flex;
        justify-content: center
    }
    .notFound_img img {
        width: 80%;
    }
} 