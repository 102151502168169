.slider {
  position: relative;
  width: 100%;
  /* height: 700px; */
  height: 80vh;
  margin: 50px 0 30px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
  animation: slideFade 1.5s ease-in-out;
}

.slide.active {
  opacity: 1;
  animation: slideIn 1.5s ease-in-out;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes opacitiAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideFade {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
  .slider {
    height: 450px;
  }
}

@media all and (max-width: 768px) and (min-width: 480px) {
  .slider {
    height: 400px;
  }
}

@media all and (max-width: 480px) and (min-width: 360px) {
  .slider {
    height: 300px;
    width: 99%;
    margin-top: 25px;
  }
}

@media all and (max-width: 360px) {
  .slider {
    height: 200px;
    margin-top: 25px;
  }
}