.hide {
    display: none;
}

.show {
    display: block;
}

.container {
    max-width: 1300px;
    margin: 0 auto;
}

.container h1{
    font-family: 'Bebas', 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 113.2%;
    color: #FFFFFF;
    padding-bottom: 50px;
}

.breadСrumbs {
    max-width: 400px;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 20px;
}

.breadСrumbs a{
    color: #707070;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
}

.productInfoTabs {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.productInfoTabs h1 {
    padding-bottom: 50px;
}

.tabContent {
    border-top: 1px solid #008FFF;
    padding-bottom: 40px;
}

.styleTab {
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #FFFFFF;
    padding: 5px 17px;
}

.productInfoTabs .activeLinks {
    background-color: #008FFF;
}

.activeLinks {
    background-color: #008FFF;
}

.productInfoTabs button:hover{
    background-color: #008FFF;
    transition: 0.3s;
}

.productInfoContent {
    width: 100%;
}

.infoTab {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.productInfoContentLeft {
    max-width: 751px;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}
.productInfoContentLeft li {
    /* font-size: 18px; */
    font-size: 16px;
}
.productInfoContentLeft p {
    /* font-size: 18px; */
    font-size: 16px;
}
.productInfoContentLeft  {
    line-height: 25px;
}
.productInfoContentLeft .productInfoText{
    max-width: 751px;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    color: #FFFFFF;
}
.productInfoContentLeft .productInfoTextH2{
    max-width: 751px;
    font-family: 'Bebas', 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #FFFFFF;
    line-height: 40px;
    letter-spacing: 2px;
    margin-bottom: 20px;
}

.productInfoContentLeft ul{
    position: relative;
    left: 30px;
}

.productInfoContentRight {
    width: 508px;
    min-height: 411px;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.productInfoContentRight ul{
    border: 1px solid #707070;
    border-radius: 5%;
    padding: 30px;
}
.productInfoContentRight .listItemSubspeciesUl {
    position: relative;
    left: 30px;
    border: none;
    padding: 5px;
    cursor: auto;
}

.listItem {
    padding-bottom: 10px;
}

.listItemDiv {
   
    padding-bottom: 0;
}

.accordeonContainer {
    border: 1px solid #707070;
    border-radius: 5%;
    padding: 30px;
}

.listItem:hover {
    color: #008FFF;
    transition: 0.3s;
    cursor: pointer;
}

.listItemActive {
    color: #008FFF;
    transition: 0.3s;
    cursor: pointer;
}

.listItemSubspeciesLi {
    width: fit-content;
    list-style-type: disc;
    cursor: pointer;
}

.listItem:hover::before {
    display: inline-block;
    top: -3px;
    transform: rotate(180deg);
    transition: 0.3s;
}

.listItem {
    list-style: outside;
}

.productInfoContentRight ul {
    list-style:none;
}

.listItem {
    list-style:none;
}

.listItem:before {
    content: url(../../../../icons/productInfoIIcons.svg);
    position: relative;
    left: -5px;
    top: 3px;
}

.iksweb{
	width: 95%;
	border-collapse:collapse;
	border-spacing:0;
	height: auto; 
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgb(113 113 113 / 25%);
}
.iksweb,table.iksweb td, table.iksweb th {
	border: 4px solid #595959; }
.iksweb td,table.iksweb th {
	min-height:35px;padding: 3px; 
	width: 30px;
	height: 35px; }
.iksweb th {
	background: #347c99;
	color: #fff;
	font-weight: normal; }



@media screen and (max-width: 1024px) and (min-width: 480px){
    .container {
        max-width: 1024px;
        margin: 0 auto;
        padding: 20px;
    }
    .infoTab {
        flex-direction: column;
        gap: 10px;
    }
    .productInfoContentLeft {
        width: 100%;
    }
    .productInfoContentRight {
        width: 100%;
    }

    .tabContent {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
    }
    .container h1{
        padding-bottom: 20px;
    }
    
}

@media screen and (max-width: 768px) and (min-width: 480px){
    .container h1{
        font-size: 40px;
    }
}

@media screen and (max-width: 480px) and (min-width: 360px){
    .container h1{
        font-size: 30px;
    }
    .productInfoContentLeft {
        font-size: 14px;
    } 
    .infoTab {
        flex-direction: column;
        gap: 10px;
    }
    .productInfoContentLeft {
        width: 100%;
    }
    .productInfoContentRight {
        width: 100%;
    }
}

@media screen and (max-width: 480px){
    .iksweb {
        display: block;
        overflow-x: scroll;
    }
}

@media screen and (max-width: 360px) {
    .container h1{
        font-size: 20px;
    }
    .productInfoContentLeft {
        font-size: 14px;
    } 
    .infoTab {
        flex-direction: column;
        gap: 10px;
    }
    .productInfoContentLeft {
        width: 100%;
    }
    .productInfoContentRight {
        width: 100%;
    }
}