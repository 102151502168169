.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
  position: relative; /* Добавьте это свойство */
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  z-index: 1; /* Добавьте это свойство */
}

.popup-content img {
  display: block;
  margin: 0 auto;
  position: relative; /* Добавьте это свойство */
  z-index: 2; /* Добавьте это свойство */
}

.popup-content-img {
  width: 500px;
  height: 700px;
  z-index: 999;
}

@media all and (max-width: 768px) and (min-width: 480px) {
  .popup-content-img {
    width: 300px;
    height: 400px;
  }
}

@media all and (max-width: 480px) {
  .popup-content-img {
    width: 200px;
    height: 300px;
  }
}
