.container {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 0 20px 50px 20px;
}

.certificate_list_title {
  font-family: 'Bebas', 'SansPro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 113.2%;
  color: #ffffff;
}

.certificate_img_block_line {
  width: 99%;
  display: none;
}

.certificate_list_description {
  font-family: 'SansPro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 31px;
  color: #ffffff;
  text-align: left;
}

.certificate_list_description_span {
  color: #008fff;
}

.certificate_list_text {
  display: flex;
  flex-direction: column;
  max-width: 532px;
}

.certificate_list_upBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.certificate_img_block_one, .certificate_img_block_two {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.certificate_image {
  width: 305px;
  height: 439px;
}

.certificate_image:hover {
  scale: 1.05;
  cursor: pointer;
  transition: 0.3s;
}

.certificate_list_upBlock_img {
  display: flex;
  gap: 21px;
}

@media all and (max-width: 1225px) and (min-width: 1024px){
  .container {
    max-width: 1225px;
  }
  .certificate_list_text {
    max-width: 100%;
  }
  .certificate_img_block_one, .certificate_img_block_two {
    display: none;
  }
  .certificate_img_block_line {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .certificate_image {
    width: 23%;
    height: auto;
  }
}

@media all and (max-width: 1024px) and (min-width: 768px) {
  .container {
    max-width: 1024px;
  }

  .certificate_list_text {
    max-width: 480px;
  }
  .certificate_list_title {
    font-size: 50px;
  }

  .certificate_list_description {
    font-size: 18px;
  }

  .certificate_image {
    width: 230px;
    height: 330px;
  }

  .certificate_img_block_line {
    display: none;
  }

  .certificate_img_block_one,
  .certificate_img_block_two {
    display: flex;
    gap: 15px;
  }
}

@media all and (max-width: 1015px) and (min-width: 768px){
  .container {
    max-width: 1015px;
  }
  .certificate_list_text {
    max-width: 100%;
  }
  .certificate_img_block_one, .certificate_img_block_two {
    display: none;
  }
  .certificate_img_block_line {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .certificate_image {
    width: 23%;
    height: auto;
  }
}

@media all and (max-width: 768px) and (min-width: 550px){
  .container {
    max-width: 768px;
  }
  .certificate_list_text {
    max-width: 100%;
  }

  .certificate_img_block_one, .certificate_img_block_two {
    display: none;
  }
  .certificate_img_block_line {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  .certificate_list_title{
    font-size: 40px;
  }

  .certificate_list_description {
    font-size: 16px;
  }
  .certificate_image {
    max-width: 23%;
    height: auto;
  }

}

@media all and (max-width: 550px) and (min-width: 480px){
  .container {
    max-width: 650px;
  }
  .certificate_list_text {
    max-width: 100%;
  }
  .certificate_list_title {
    font-size: 30px;
  }

  .certificate_img_block_one, .certificate_img_block_two {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }
  .certificate_img_block_line {
    display: none;
  }

  .certificate_list_description {
    font-size: 16px;
  }
  .certificate_image {
    width: 48%;
    height: auto;
  }

  .certificate_list_upBlock {
    max-width: 750px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
@media all and (max-width: 480px) {
  .container {
    max-width: 480px;
  }
  .certificate_list_text {
    max-width: 100%;
  }

  .certificate_img_block_one, .certificate_img_block_two {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .certificate_img_block_line {
    display: none;
  }
  .certificate_list_title{
    font-size: 30px;
  }

  .certificate_list_description{
    font-size: 16px;
  }
  .certificate_image {
    width: 48%;
    height: auto;
  }

  .certificate_list_upBlock {
    max-width: 750px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media all and (max-width: 360px) {
  .container {
    max-width: 360px;
  }

  .certificate_list_title {
    font-size: 20px;
  }

  .certificate_list_description {
    font-size: 14px;
  }
} 

 