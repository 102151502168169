.navLink {
    display: inline-block;
    color: var(--white);
    font-family: 'SansPro', sans-serif;
    font-size: 22px;
    font-weight: 600;
}
.navLink:hover {
    color: var(--blue);
    display:block;
    content: '';
    border-bottom: solid 3px var(--blue);  
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdownList {
    display: none;
    position: absolute;
    right: -218px;
    top: 0px;
    background-color: #333333;
    min-width: 218px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 15px 0px 39px 30px;
    z-index: 1;
}
.dropdown:hover .dropdownList {
    display: block;
}
.dropdownList a {
    color: #fff;
    padding: 8px 16px;
    text-decoration: none;
    font-family: 'SansPro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: block;
}
.dropdownList a:hover {
    background-color: #333333;
    color: var(--blue);
}
.dropdownList a.active {
    background-color: #4CAF50;
    color: white;
}

@media all and (max-width: 1024px) {
    .navLink {
        font-family: 'SansPro', sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
}

@media all and (max-width: 768px) {
    .dropdownList {
        /* right: -182px; */
        right: -235px;
    }
    .navLink {
        font-family: 'SansPro', sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
}

@media all and (max-width: 768px) and (min-width: 480px) {
    .dropdownList {
        padding: 11px 0px 16px 15px;
    }

    .dropdownList a {
        padding: 10px 20px;
        font-size: 16px;
    }
}

@media all and (max-width: 365px) {
    .dropdownList {
        padding: 11px 0px 16px 15px;
    }

    .dropdownList a {
        padding: 4px 16px;
        font-size: 14px;
    }
}