.footerContainer {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;
    padding: 40px;
}

.logo {
    width: 160px;
    height: 55px;
    margin-right: 10px;
}

.footerLinks {
    display: flex;
    flex-direction: column;
}

.footerRightLinksRouting {
    display: flex;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: var(--white);
    align-self: flex-end;
}

.footerRightLinksRouting a{
    padding-right: 20px;
}

.footerRightLinksRouting a:hover {
    color: var(--blue);
    text-decoration: underline;
}

.footerRightLinksRouting a:last-child{
    padding-right: 0;
}

.footerRightLinksProducts {
    display: flex;
    gap: 19px;
    padding-top: 20px;
}

.footerRightLinksProductsColumn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
    gap: 19px;
}
/* .footerRightLinksProducts a{
    padding-right: 19px;
} */

/* .footerRightLinksProducts a:last-child{
    padding-right: 0;
} */

.footerRightLinksProductsColumn a:hover {
    color: var(--blue);
    text-decoration: underline;
}

.brFooter {
    display: none;
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .logo{
        width: 175px;
        height: 60px;
    }
    .footerContainer {
        max-width: 1024px;
        padding: 20px;
    }
    .footerRightLinksProducts {
        flex-direction: column;
    }
    .footerRightLinksProductsColumn {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
    }
}

@media all and (max-width: 768px) and (min-width: 580px) {
    .logo{
        width: 165px;
        height: 56px;
    }
    .footerContainer {
        max-width: 768px;
        padding: 20px;
    }
    .footerRightLinksProductsColumn {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 15px;
    }
    .footerRightLinksProductsColumn a{
        font-size: 14px;
    }
    .footerRightLinksProducts {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8px;
    }

    .footerRightLinksRouting a{
        font-size: 16px;
    }
}

@media all and (max-width: 580px) {
    .footerContainer {
        max-width: 99%;
        padding: 20px;
        align-items: center;
    }
    .logo {
        display: none;
    }
    .footerRightLinksProductsColumn {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 8px;
    }
    .footerRightLinksProductsColumn a{
        font-size: 14px;
    }
    .footerRightLinksProducts {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8px;
    }

    .footerRightLinksRouting a{
        font-size: 16px;
    }
}

@media all and (max-width: 420px) and (min-width: 360px) {
    .footerContainer {
        max-width: 99%;
        padding: 20px;
    }
    .footerRightLinksProductsColumn {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 8px;
        flex-wrap: wrap;
    }
    .footerRightLinksProductsColumn a{
        font-size: 12px;
    }
    .footerRightLinksProducts {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8px;
    }

    .footerRightLinksRouting a{
        font-size: 14px;
        padding-right: 15px;
    }
    .footerRightLinksRouting {
        flex-wrap: wrap;
    }
}

@media all and (max-width: 360px) {
    .footerContainer {
        max-width: 99%;
        padding: 20px;
    }
    .footerRightLinksProductsColumn {
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        flex-wrap: wrap;
    }
    .footerRightLinksProductsColumn a{
        font-size: 12px;
    }
    .footerRightLinksProducts {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 8px;
    }

    .footerRightLinksRouting a{
        font-size: 14px;
        padding-right: 15px;
    }
    .footerRightLinksRouting {
        flex-wrap: wrap;
    }
}