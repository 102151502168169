.preFooterContainer {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding-left: 20px;
    padding-right: 20px;
}

.leftPreFooter {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* .leftPreFooter div:nth-child(2) {
    padding-top: 10px;
} */

.leftPreFooter div{
    max-width: 700px;
    height: 20px;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
}

.rightPreFooter {
    display: flex;
    justify-content: space-between;
    align-items:center;
}

.rightPreFooterLinks {
    display: flex;
    flex-direction: column;
}

.rightPreFooterLinksText {
    height: 20px;
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
    padding-right: 40px;
}

.rightPreFooterLinksText:hover {
    color: var(--blue);
}

.rightBlok {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 20px;
}

.downloadBtn {
    position: relative;
    text-align: center;
    /* margin-right: -10px; */
}

.downloadBtnisScreenFOOT {
    width: 100%;
    position: relative;
}

.downloadBtnText {
    color: var(--blue);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 16px;
    font-family: 'Bebas', 'SansPro', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
}

.downloadBtn:hover {
    .downloadBtnText {
        color: var(--white);
    }

    .downloadBtnIcon {
        fill: var(--blue);
    }
}

@media screen and (max-width: 1024px) and (min-width: 768px){
    .preFooterContainer {
        flex-direction: row;
        /* justify-content: center; */
        gap: 10px;
        max-width: 100%;
        margin: 0 auto;
        padding: 20px;
        padding-top: 0;
    }

    .leftPreFooter {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        line-height: 30px;
        white-space: nowrap;
    }
    .leftPreFooter div{
        max-width: 700px;
        font-weight: 400;
    }
    .rightPreFooter {
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        /* margin-left: 15%; */
    }
    .rightPreFooterLinks {
        display: flex;
        justify-content: center;
        margin-left: 46px;
    }
    .rightPreFooterImg {
        padding-right: 18px;
    }
}

@media screen and (max-width: 768px){
    .preFooterContainer {
        max-width: 768px;
        margin: 0 auto;
        padding: 20px;
        padding-top: 0;
        padding-bottom: 40px;
    }
    * {
        font-size: 14px;
    }

    .leftPreFooter {
        /* display: flex;
        flex-direction: column; */
        max-width: 471px;
        gap: 20px;
        line-height: 30px;
    }

    .rightPreFooter {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        max-width: 371px;
        text-align: right;
        margin-top: 10px;
    }
    
    .rightPreFooterImg {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items:center;
        margin-bottom: 7px;
    }

    .downloadBtnText{
        font-family: 'Bebas', 'SansPro', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color : var(--blue);
        margin-bottom: 10px;
    }
    .rightPreFooterImgColumnReverse {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-left: 15px;
    }
    .rightPreFooter {
        margin-top: 0;
    }
    .downloadBtnTextColumnReverse {
        width: 100%;
        /* text-align: right; */
        font-size: 16px;
        font-family: 'Bebas', 'SansPro', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        color: var(--blue);
    }
    .downloadBtnTextColumnReverseIphone {
        width: 100%;
        color: var(--blue);
        font-size: 16px;
        font-family: 'Bebas', 'SansPro', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
    }
    .rightPreFooterLinksText {
        font-size: 14px;
    }
}
@media screen and (max-width: 580px) {
    .logo {
        width: 165px;
        height: 56px;
        margin-left: 20px;
    }
}
@media screen and (max-width: 480px) {
    .leftPreFooter {
        display: none;
    }

    .logo {
        /* width: 8em; */
        width: 165px;
        /* height: 3.125em; */
        height: 56px;
        margin-left: 20px;
    }

    .preFooterContainer {
        max-width: 99%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 0 20px 0;
    }

    .rightPreFooterImgColumnReverse {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-left: 15px;
    }
    .rightPreFooter {
        margin-top: 0;
    }
    .downloadBtnTextColumnReverse {
        width: 100%;
        /* text-align: right; */
        font-size: 16px;
        font-family: 'Bebas', 'SansPro', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        color: var(--blue);
    }
     .downloadBtnTextColumnReverseIphone {
        width: 100%;
        color: var(--blue);
        font-size: 14px;
        font-family: 'Bebas', 'SansPro', sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        margin-right: 21px;
    }
    .rightPreFooterLinksText {
        font-size: 14px;
    }
}

@media screen and (max-width: 360px){
    .preFooterContainer {
        max-width: 360px;
        margin: 0 auto; 
        padding: 20px;
        padding-top: 0;
    }
   .leftPreFooter {
    display: none;
   }
   .logo {
    width: 8em;
    height: 3.125em;
    margin-left: 20px;
   }
   .preFooterContainer {
    max-width: 440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 0 0 20px 0;
}
}