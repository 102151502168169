.navLink:hover {
    color: var(--blue);
    display:block;
    content: '';
    border-bottom: solid 3px var(--blue);  
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdownList {
    display: none;
    position: absolute;
    right: -67px;
    background-color: #333333;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 10px 0px 19px 10px;
    z-index: 1;
}
.dropdown:hover .dropdownList {
    display: block;
}
.dropdownList a {
    color: #fff;
    padding: 8px 16px;
    text-decoration: none;
    font-family: 'SansPro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    display: block;
}
.dropdownList a:hover {
    background-color: #333333;
    color: var(--blue);
}
.dropdownList a.active {
    background-color: #4CAF50;
    color: white;
}

.dropdownTitleFlex {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    font-size: 16px;
    padding-bottom: 17px;
}

.dropdownItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.dropdownItem button{
    text-align: left;
}
.dropdownItem button:hover{
    cursor: pointer;
}

@media all and (max-width: 768px) {
    .dropdownList {
        right: -67px;
    }
  }
