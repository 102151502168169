.contactH1 {
    font-family: 'Bebas', 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 113.2%;
    color: #FFFFFF;
    padding-bottom: 30px;
}

.text_width {
    max-width: 1300px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 20px;
}

.map {
    position: relative;
}

.map iframe{
    width: 100%;
	filter: grayscale(1);
	-ms-filter: grayscale(1);
	-webkit-filter: grayscale(1);
	-moz-filter: grayscale(1);
	-o-filter: grayscale(1);
    mix-blend-mode: difference;
}

.difference {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999;
    background-color: white;
    mix-blend-mode: difference;
    pointer-events: none;
}

.contactInfo {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 30px;
}

.contactInfo_text {
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    text-align: left;
}

.contactInfo_title {
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
}

.contactInfo_btn {
    font-family: 'SansPro', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #008FFF;
}

.leftBlock div{
    width: 308px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.leftBlock a{
   padding-top: 12px;
}

.contactInfo_btn {
    display: flex;
    align-items: flex-start;
    gap: 6px;  
}
.contactInfo_btn svg{
    width: 10px;
}

.centerBlock div{
    width: 140px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.rightBlock div{
    width: 130px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
}

.rightBlockMobile{
    display: none;
}

@media all and (max-width: 1024px) and (min-width: 768px) {
    .contact_main { 
        padding: 20px;
    }
    .text_width {
        max-width: 940px;
        margin: 0 auto;
    }
    .contactInfo {
        max-width: 865px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
    }
    .map {
        width: 100%;
    }
}
@media all and (max-width: 768px) and (min-width: 480px) {
    .contact_main {
        padding: 20px;
    }
    .text_width {
        max-width: 700px;
        margin: 0 auto;
    }
    .contactInfo {
        max-width: 700px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
    }
    .contactH1 {
        font-family: 'Bebas', 'SansPro', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 113%;
        color: #FFFFFF;
        padding-bottom: 30px;
    }
}
@media all and (max-width: 480px) {
    .contact_main {  
        max-width: 440px;
        padding: 20px;
    }
    .text_width {
        max-width: 440px;
        margin: 0 auto;
    }
    .contactInfo {
        max-width: 440px;
        display: flex;
        justify-content: flex-start;
        gap: 30px;
        padding-bottom: 30px;
    }
    .contactInfo .contactInfo_btn{
        align-self: flex-start;
    }
    .map {
        max-width: 440px;
    }
    .contactH1 {
        font-size: 30px;
        line-height: 33px;
        font-weight: 400;
    }
    .contactInfo_text {
        font-size: 16px;
    }
    
    .contactInfo_title {
        font-size: 14px;
    }
    .rightBlock {
        display: none;
    }

    .rightBlockMobile {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .rightBlockMobileRecvisit {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
    .rightBlockMobileRecvisit div{
        display: flex;
        flex-direction: column;
    }
    .rightBlockMobileRecvisit div span{
        align-self: flex-end;
    }
    .contactInfo_btn {
        align-self: flex-end;
    }
    .leftBlock div {
        max-width: 220px;
    }
}
@media all and (max-width: 360px) {
    .contact_main {
        max-width: 320px;
        padding: 20px;
    }
    .contactInfo {
        max-width: 320px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
    }
    .map {
        max-width: 360px;
    }
    .contactH1 {
        font-size: 20px;
        line-height: 22px;
        font-weight: 400;
    }
    .contactInfo_text {
        font-size: 14px;
    }
    
    .contactInfo_title {
        font-size: 14px;
    }
    .leftBlock div{
        max-width: 163px;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }
}
